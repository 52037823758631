.tes-view-layout {
    &-title {
        display: none; //flex
        align-items: center;
        justify-content: center;
        width: 100%;
        animation: slideFromTop 1s forwards;

        @include desktop {
            height: 300px;
        }

        @include mobile {
            display: none;
        }

        .title {
            animation: slideFromTop 1.5s forwards;
            backdrop-filter: blur(5px);
        }
    }

    &-subtitle {
        animation: slideFromLeft 1s ease-in-out;

        p {
            animation: slideFromLeft 1.5s ease-in-out;
        }
    }

    &-content {
        animation: slideFromBottom 1s ease-in-out;

        p.message {
            animation: none;
        }

        p, input, li {
            animation: slideFromBottom 1.5s ease-in-out;
        }
    }
}
