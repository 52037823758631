.tes-about {
    &__header {
        animation: slideFromLeft 1s ease-in-out;
    }

    .tile.is-parent {
        animation: fadeIn 1s ease-in-out forwards;
        transition: 0.7s;

        &:hover {
            transform: scale3d(1.05, 1.05, 1.05);
        }
    }

    .left {
        animation: slideFromLeft 1.5s ease-in-out;
    }

    .right {
        animation: slideFromRight 1.5s ease-in-out;
    }
}
